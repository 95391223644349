export const environment = {
    e2e: false,
    production: true,
    firebase: {
        apiKey: "AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU",
        authDomain: "dcsptest-sso.service.deloitte.ai",
        projectId: "dcsptest",
    },
    region: "europe-west1",
    brain_base_url: "https://deloitte-test.apigee.net/cwl-woven",
    aoh_url: "https://aoh-qa.5064c7381d705f073d0a628e1f5c0a3b.cwl.camp",
    store_url: "https://store-qa.5064c7381d705f073d0a628e1f5c0a3b.cwl.camp",
    api_base_url: "https://deloitte-test.apigee.net",
    authParams: ["oidc.azure"],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    useBilling: true,
    upload: {
        allowUnclassifiedFilesUpload: true,
        confidentialMetadata: [],
        existingMetadata: []
    },
    onboarding: {
        displayVideo: false,
        video_url: "",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:mahorst@deloitte.de?subject=Question%20regarding%20CAMPfire%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:mahorst@deloitte.de?subject=Question%20regarding%20CAMPfire%20Marketplace"
    },
    onboarding_options: {
        "manual": {},
        "api_passthrough": {
            authTypes: [
                { label: 'service_owner.technical.self_service.api_key', value: 'api_key' },
                { label: 'service_owner.technical.self_service.token_based_auth', value: 'bearer_token' }
            ],
            onboarding_manager: "mailto:GlobalDeloitteAi@deloitte.com?subject=API%20Onboarding%20I%20Ready%20for%20review"
        },
        "soft_onboarding": {}
    }
};
